import { useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import Button from "../Button/Button";
import { PAGE_STR } from "../../assets/txt/str";
import "./NotFound.css";

const TITLE = PAGE_STR[404].title;
const RETURN_BTN_TXT = "Return to homepage";

function NotFound() {
  const NAVIGATE = useNavigate();
  const NAVIGATE_TO_HOME = () => {
    NAVIGATE("/");
  };

  useEffect(() => {
    document.title = TITLE;
  });

  return (
    <main className="container">
      <div className="padded-container">
        <h1 className="page-header">{PAGE_STR[404].header}</h1>
        <h2 className="home-tagline">{PAGE_STR[404].subHeader}</h2>
        <p className="tagline">
          For inquiries and clarifications, please call us on{" "}
          <Link className="info" to="tel:+94773170955">
            +94 (77)317 0955
          </Link>{" "}
          or email us on{" "}
          <Link className="info" to="mailto:mat.perera@gmail.com">
            mat.perera@gmail.com
          </Link>
          .
        </p>
        <div className="return-to-home">
          <Button text={RETURN_BTN_TXT} onClick={NAVIGATE_TO_HOME} />
        </div>
      </div>
    </main>
  );
}

export default NotFound;
