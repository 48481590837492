import { NavLink } from "react-router-dom";
import {
  FaHouse,
  FaBuffer,
  FaRegCircleQuestion,
  FaPaperPlane,
} from "react-icons/fa6";
import { IconContext } from "react-icons";
import "./Tabs.css";

function Tabs() {
  return (
    <div className="tabs-menu">
      <IconContext.Provider value={{ size: 16 }}>
        <NavLink className="tab" to="/">
          <FaHouse className="tab-icon" />
        </NavLink>
        <NavLink className="tab" to="/products">
          <FaBuffer className="tab-icon" />
        </NavLink>
        <NavLink className="tab" to="/about-us">
          <FaRegCircleQuestion className="tab-icon" />
        </NavLink>
        <NavLink className="tab" to="/contact-us">
          <FaPaperPlane className="tab-icon" />
        </NavLink>
      </IconContext.Provider>
    </div>
  );
}

export default Tabs;
