import { Link } from "react-router-dom";
import { IconContext } from "react-icons";
import {
  FaInstagram,
  FaLinkedin,
  FaFacebook,
  FaEnvelope,
  FaRegPaperPlane,
  FaPhone,
} from "react-icons/fa6";
import { PAGE_STR } from "../../assets/txt/str";
import "./Footer.css";

function Footer() {
  return (
    <footer className="Footer">
      <div className="footer-content">
        <div className="social">
          <IconContext.Provider value={{ size: 20 }}>
            <Link to="mailto:mat.perera@gmail.com">
              <FaEnvelope />
            </Link>
            <Link to="tel:+94773170955" target="_blank">
              <FaPhone />
            </Link>
            <Link to="https://instagram.com" target="_blank">
              <FaInstagram />
            </Link>
            <Link to="https://linkedin.com" target="_blank">
              <FaLinkedin />
            </Link>
            <Link to="/contact-us">
              <FaRegPaperPlane />
            </Link>
          </IconContext.Provider>
        </div>
        <hr className="footer-content-hr" />
        <p>{PAGE_STR.footer.copyright}</p>
        <p>{PAGE_STR.footer.rights}</p>
      </div>
    </footer>
  );
}

export default Footer;
