import { useEffect } from "react";
import { PAGE_STR, ABOUT_STR } from "../../assets/txt/str";
import "./About.css";

const TITLE = PAGE_STR.about.title;
const SUPPLIERS = ABOUT_STR.suppliers;

function About() {
  useEffect(() => {
    document.title = TITLE;
  });
  return (
    <main className="container">
      <h1 className="page-header padded-container">{PAGE_STR.about.header}</h1>
      <div className="blockqoute-container">
        <p className="qoute">{ABOUT_STR.qoute.qoute}</p>
        <p className="author">~ {ABOUT_STR.qoute.authur}</p>
      </div>
      <div className="padded-container">
        <p className="company-desc">{ABOUT_STR.companyDescription}</p>
        <div className="vision-mission">
          <div className="vision">
            <h2 className="vision-subheader">
              {PAGE_STR.about.subHeader.vision}
            </h2>
            <p>{ABOUT_STR.vision}</p>
          </div>
          <div className="mission">
            <h2 className="mission-subheader">
              {PAGE_STR.about.subHeader.mission}
            </h2>
            <p>{ABOUT_STR.mission}</p>
          </div>
        </div>
        <div>
          <h2 className="page-header about-subheader">
            {PAGE_STR.about.subHeader.suppliers.toUpperCase()}
          </h2>
          {Object.keys(SUPPLIERS).map((key) => {
            return (
              <div key={SUPPLIERS[key].id} className="supplier">
                <h2 className="item-header">{SUPPLIERS[key].name}</h2>
                <div className="supplier-content">
                  <img src={SUPPLIERS[key].img} alt={SUPPLIERS[key].name} />
                  <div className="supplier-desc">
                    <h3 className="tagline">{SUPPLIERS[key].subHeader}</h3>
                    <p>{SUPPLIERS[key].description}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </main>
  );
}

export default About;
