import { Link, NavLink, useNavigate } from "react-router-dom";
import Button from "../Button/Button";
import Logo from "../../assets/media/logo.png";
import "./Header.css";

function Header() {
  const PAPER_PLANE_ICON = "FaPaperPlane";
  const NAVIGATE = useNavigate();
  const NAVIGATE_TO_CONTACTS = () => {
    // TODO: Should be fixed
    NAVIGATE("/contact-us");
    // NAVIGATE("/404");
  };

  return (
    <div className="header-container">
      <header className="header">
        <Link to="/">
          <div id="header-logo-container">
            <img className="header-logo" src={Logo} alt="Logo" />
          </div>
        </Link>
        <nav className="main-nav">
          <NavLink to="/" className="nav-item">
            Home
          </NavLink>
          <NavLink to="/products" className="nav-item">
            Products
          </NavLink>
          <NavLink to="/about-us" className="nav-item">
            About Us
          </NavLink>
        </nav>
        <div>
          <Button
            id="contact-btn"
            onClick={NAVIGATE_TO_CONTACTS}
            icon={PAPER_PLANE_ICON}
          />
        </div>
      </header>
    </div>
  );
}
export default Header;
