import axios from "axios";

export const publishSNS = async (subject, message) => {
  const apiUrl = "https://mat-react-backend.vercel.app/sns";
  const body = { subject: subject, message: message };
  return await axios
    .post(apiUrl, body)
    .then(function (response) {
      return response.status;
    })
    .catch(function (err) {
      return err.response.status;
    });
};
