// import Placeholder from "../media/placeholder.png";
import Tacteasy from "../media/tacteasy.png";
import Optoma from "../media/optoma.png";
import ITC from "../media/itc.png";
import TA65 from "../media/tacteasy-ta65.png";
import TA86 from "../media/tacteasy-ta86.png";
import W319ST from "../media/optoma-w319st.png";
import W309ST from "../media/optome-w309st.png";
import X309ST from "../media/optoma-x309st.png";

const COMPANY = "MAT International (Pvt) Ltd.";
const YEAR = new Date().getFullYear();

// Page headers and subheaders
export const PAGE_STR = {
  home: {
    title: "MAT | Home of the best smart classroom equipment in Sri Lanka.",
    header: "WE ARE MAT!",
    subHeader:
      "Welcome to the world of the best smart classroom equipment in Sri Lanka.",
  },
  products: {
    title: "MAT | Take a peak at our portfolio.",
    header: "OUR PRODUCTS",
  },
  about: {
    title: "MAT | Who are we?",
    header: "WHO ARE WE?",
    subHeader: {
      history: "Where it all started",
      suppliers: "Our suppliers",
      vision: "Our vision",
      mission: "Our mission",
    },
  },
  contact: {
    title: "MAT | Let's get in touch!",
    header: "LET'S TALK!",
  },
  footer: {
    year: YEAR,
    company: COMPANY,
    copyright: `\xa9 ${YEAR} ${COMPANY} `,
    rights: "All rights reserved.",
    trademark: `MAT\xaE is a registered trademark of ${COMPANY}`,
  },
  404: {
    title: "404 | Page not found :(",
    header: "Error 404 :(",
    subHeader:
      "The page you are looking for is currently unavailable. We sincerely apologize for any inconvenience caused.",
  },
};

// Product page content
export const PRODUCT_STR = [
  {
    id: 1,
    name: "Tacteasy TA65",
    subHeader: "Meetings and lesson redefined.",
    description:
      "This 65-inch 4K UHD LED display is packed with features that make it perfect for classrooms, conference rooms, and more. If you are looking for a top-of-the-line all-in-one interactive whiteboard that will make your presentations, lessons, and meetings more engaging and productive, then the Tacteasy TA65 is a great option.",
    img: TA65,
  },
  {
    id: 2,
    name: "Tacteasy TA86",
    subHeader: "Welcome to the era of all-in-one computing.",
    description:
      "This 86-inch 4K UHD LED display is a powerhouse of features, designed to elevate your meetings, lessons, and presentations to a whole new level. If you wish to revolutionize your presentations, meetings, and lessons, the Tacteasy TA86 is undoubtedly worth serious consideration.",
    img: TA86,
  },
  {
    id: 3,
    name: "Optoma W319ST",
    subHeader: "Vivid presentations, effortlessly.",
    description:
      "This compact powerhouse packs a punch with its bright 4,000 lumens WXGA resolution and a stunning 25,000:1 contrast ratio, ensuring sharp and vibrant images even in brightly lit environments. But the W319ST's true magic lies in its short throw ratio of 0.52:1. This means you can project a massive 100-inch image from just over a meter away, making it ideal for small spaces.",
    img: W319ST,
  },
  {
    id: 4,
    name: "Optoma W309ST",
    subHeader: "Bright. Compact Short throw.",
    description:
      "This compact and portable projector boasts 3,800 lumens WXGA resolution and a 25,000:1 contrast ratio, ensuring sharp and detailed images even in moderately lit environments. Its short throw lens projects a 100-inch image from just over a meter away, making it ideal solution for classrooms, meeting rooms, and even your living room.",
    img: W309ST,
  },
  {
    id: 5,
    name: "Optoma X309ST",
    subHeader: "It's portable. It's lightweight.",
    description:
      "Project bright and vibrant presentations effortlessly any time of day. Designed for small meeting rooms and classrooms, this short throw projector boasts amazing colour, a long lamp life and energy saving features for a lower overall cost of ownership. Portable and lightweight this compact projector can be installed or taken on the move for off-site meetings with the optional carry bag (sold separately).",
    img: X309ST,
  },
];

// About Us page content
export const ABOUT_STR = {
  companyDescription: `Since our inception in the year 2005, ${COMPANY} has been the pioneer in the supply of audio-visual equipment, digital smart boards, multimedia projectors, and smart classroom equipment. In 2011, for the first time in Sri Lanka, we launched our biggest product yet. The Interactive White Board. From that point onwards, we have been the spearheading Sri Lanka's IWB market with state-of-the-art products and premier after-sales services.`,
  qoute: {
    qoute:
      '"Their product is an Interactive White Board, an extension of your computer. Whatever you do in your computer, can be done in the White Board as well. If you want to save it or later retrieve it, you can do all of that. It is an amazing product they have brought down. We are fortunate to have a product like this in Sri Lanka, it helps all industries education and corporate sector."',
    authur: "Daily News",
  },
  mission:
    "To contribute to the social and economic development of Sri Lanka by equipping educational institutions with advanced learning tools and promoting the use of technology in various sectors.",
  vision:
    "To be the leading provider of innovative and high-quality projector solutions in Sri Lanka, empowering businesses, educational institutions, and individuals to unlock the power of visual communication.",
  suppliers: [
    {
      id: 1,
      name: "Tacteasy",
      subHeader: "Making technology touchable.",
      description:
        "Tacteasy is a Chinese brand specializing in interactive whiteboards and touch screen technology. They've established themselves as a leader in the field, offering innovative and competitive products for education, training, conferencing, business, and government sectors. While interactive whiteboards are a core part of their business, Tacteasy is also expanding into other areas of touch screen technology. They offer touch screen overlays for existing displays, as well as all-in-one touch screen PCs. Tacteasy is committed to providing its customers with the best possible products and experience. They have a strong focus on research and development, constantly innovating to bring new and improved products to market.",
      img: Tacteasy,
    },
    {
      id: 2,
      name: "Optoma",
      subHeader: "Bringing Your Big Picture to Life.",
      description:
        "Optoma is a global leader in large-format displays, specializing in projectors, interactive flat panels, and LED displays. From immersive home entertainment to powerful business solutions, Optoma's mission is to \"Experience More.\" Optoma is a brand that stands for innovation, quality, and a dedication to bringing people together through powerful visual experiences. Whether you're looking for a cinematic home theater setup, a dynamic classroom solution, or a captivating display for your business, Optoma has the perfect visual solution for you.",
      img: Optoma,
    },
    {
      id: 3,
      name: "ITC",
      subHeader: "Sound Solutions for Every Space",
      description:
        "ITC Audio is a leading manufacturer of professional audio equipment, specializing in public address (PA) systems, conference systems, and background music solutions. They're known for their high-quality products, innovative technology, and reliable performance, making them a trusted choice for a wide range of applications. ITC Audio is a trusted brand that provides high-quality, reliable, and innovative audio solutions for a wide range of applications. If you're looking for a pro audio system that will meet your needs and exceed your expectations.",
      img: ITC,
    },
  ],
};

// Contact Us page
export const CONTACT_STR = {
  labels: {
    name: "Name",
    email: "Email",
    phone: "Phone",
    organization: "Organization",
    message: "Message",
  },
  placeholders: {
    name: "Enter your name",
    email: "Enter your email",
    phone: "Enter your phone number",
    organization: "Company you work for",
    message: "Type your message here",
  },
  onClick: {
    ok: "You message has been recieved!",
    notOk: "An error has occured. Please try again later.",
  },
};

export const HOME_STR = {
  carousel: [
    // {
    //   id: 0,
    //   url: Tacteasy,
    //   name: "Tacteasy",
    // },
    {
      id: 1,
      url: TA65,
      name: "Tacteasy TA65",
    },
    {
      id: 2,
      url: TA86,
      name: "Tacteasy TA86",
    },
    // {
    //   id: 3,
    //   url: Optoma,
    //   name: "Optoma",
    // },
    {
      id: 4,
      url: W309ST,
      name: "Optoma W309ST",
    },
    {
      id: 5,
      url: X309ST,
      name: "Optoma X309ST",
    },
    {
      id: 6,
      url: W319ST,
      name: "Optoma W319ST",
    },
    // {
    //   id: 7,
    //   url: ITC,
    //   name: "ITC",
    // },
  ],
};
