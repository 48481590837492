import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../Button/Button";
import Slider from "react-slick";
import { PAGE_STR, HOME_STR } from "../../assets/txt/str";
import "./Home.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const TITLE = PAGE_STR.home.title;
const CAROUSEL = HOME_STR.carousel;
const SETTINGS = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
  ],
};

function Home() {
  useEffect(() => {
    document.title = TITLE;
  });
  const NAVIGATE = useNavigate();
  const NAVIGATE_TO_PRODUCTS = () => {
    NAVIGATE("/products");
  };
  return (
    <main className="container">
      <div className="padded-container">
        <h1 className="page-header">{PAGE_STR.home.header}</h1>
        <h2 className="home-tagline">{PAGE_STR.home.subHeader}</h2>
        <div className="carousel-container">
          <Slider {...SETTINGS}>
            {CAROUSEL.map((img) => (
              <div className="carousel-card" key={img.id}>
                <div className="carousel-img-container">
                  <img className="carousel-img" src={img.url} alt={img.name} />
                </div>
                <div className="carousel-txt-container">
                  <h4>{img.name}</h4>
                  <Button text="Read more" onClick={NAVIGATE_TO_PRODUCTS} />
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </main>
  );
}

export default Home;
