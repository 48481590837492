import { useEffect } from "react";
import Button from "../Button/Button";
import { PAGE_STR, CONTACT_STR } from "../../assets/txt/str";
import "./Contact.css";
import { publishSNS } from "../../services/api.service";
import { Formik, ErrorMessage, Field, Form } from "formik";
import * as Yup from "yup";
const TITLE = PAGE_STR.contact.title;

function Contact() {
  useEffect(() => {
    document.title = TITLE;
  });

  const initialValues = {
    name: "",
    email: "",
    phone: "",
    organization: "",
    message: "",
  };
  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required."),
    email: Yup.string()
      .required("Email is required.")
      .email("Email is invalid."),
    phone: Yup.string(),
    organization: Yup.string(),
    message: Yup.string().required("Message is required."),
  });

  return (
    <main className="container">
      <div className="padded-container">
        <h1 className="page-header">{PAGE_STR.contact.header}</h1>
        <Formik
          initialValues={initialValues}
          onSubmit={async (values) => {
            const body = `Date: ${new Date()} \nName: ${values.name} \nEmail: ${
              values.email
            } \nPhone: ${values.phone} \nOrganization: ${
              values.organization
            } \nMessage: ${values.message}`;
            const response = await publishSNS(
              "Porfolio: New form submission.",
              body
            );
            if (response === 201) {
              console.log(response);
              document.getElementById("submission-status").innerHTML =
                "Submission Successful. Thank you for reaching out!";
            } else {
              console.log(response);
              document.getElementById(
                "submission-status"
              ).innerHTML = `Error ${response}! Please try again.`;
            }
          }}
          validationSchema={validationSchema}
        >
          {() => (
            <Form>
              <div className="field">
                <Field name="name" placeholder={CONTACT_STR.labels.name} />
                <div className="error-message">
                  <ErrorMessage name="name" component="span" />
                </div>
              </div>
              <div className="field">
                <Field name="email" placeholder={CONTACT_STR.labels.email} />
                <div className="error-message">
                  <ErrorMessage name="email" component="span" />
                </div>
              </div>
              <div className="field">
                <Field name="phone" placeholder={CONTACT_STR.labels.phone} />
                <div className="error-message">
                  <ErrorMessage name="phone" component="span" />
                </div>
              </div>
              <div className="field">
                <Field
                  name="organization"
                  placeholder={CONTACT_STR.labels.organization}
                />
                <div className="error-message">
                  <ErrorMessage name="organization" component="span" />
                </div>
              </div>
              <div className="field">
                <Field
                  name="message"
                  component="textarea"
                  placeholder={CONTACT_STR.labels.message}
                />
                <div className="error-message">
                  <ErrorMessage name="message" component="span" />
                </div>
              </div>
              <div id="submit-btn-container">
                <Button type="submit" text="Submit" />
              </div>
            </Form>
          )}
        </Formik>
        <p id="submission-status"></p>
      </div>
    </main>
  );
}

export default Contact;
