import { useEffect } from "react";
import { PRODUCT_STR, PAGE_STR } from "../../assets/txt/str";
import "./Products.css";

const TITLE = PAGE_STR.products.title;

function Products() {
  useEffect(() => {
    document.title = TITLE;
  });

  return (
    <main className="container">
      <div className="padded-container">
        <h1 className="page-header">{PAGE_STR.products.header}</h1>
        {Object.keys(PRODUCT_STR).map((key) => {
          return (
            <div key={PRODUCT_STR[key].id} className="product">
              <h2 className="item-header">{PRODUCT_STR[key].name}</h2>
              <div className="product-content">
                <img
                  className="product-img"
                  src={PRODUCT_STR[key].img}
                  alt="Placeholder"
                />
                <div className="product-desc">
                  <h3 className="tagline">{PRODUCT_STR[key].subHeader}</h3>
                  <p>{PRODUCT_STR[key].description}</p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </main>
  );
}

export default Products;
