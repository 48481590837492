import { IconContext } from "react-icons";
import { FaPaperPlane } from "react-icons/fa6";
import "./Button.css";

function Button(props) {
  return (
    <button className="Button" id={props.id} onClick={props.onClick}>
      {props.text}
      {props.icon === "FaPaperPlane" ? (
        <IconContext.Provider value={{ size: 20 }}>
          <FaPaperPlane />
        </IconContext.Provider>
      ) : null}
    </button>
  );
}

export default Button;
